import React from 'react';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import {Form } from 'reactstrap';
import './App.css';
import Body from './layout/Body';
import {
  PerformTrainingPageNavigation, 
  PerformTrainingPageControls, 
  PerformTrainingPageInfo,
  PerformTrainingCheckListPage
} from './views/PerformTrainingPage';

const cs_max_int = 2147483647;

let defaultSteps = [{
  Index: 0,
  StepType: 2,
  Title: "Loading",
  Information: "INSTRUCT is loading your training"
},{
  Index: 0,
  StepType: 2,
  Title: "Introduction",
  AudioFilepath: null,
  ImageFilepath: 'https://electrek.co/wp-content/uploads/sites/3/2022/02/ride1up-roadster-v2-gravel-review_15.jpg'
},{
  Index: 1,
  StepType: 3,
  Title: "Step One",
  AudioFilepath: null,
  ImageFilepath: 'https://electrek.co/wp-content/uploads/sites/3/2022/02/ride1up-roadster-v2-gravel-review_15.jpg'
},{
  Index: 2,
  StepType: 4,
  Title: "Step One",
  VideoFilepath: 'http://localhost/Files/Public/FindMazer.mp4',
  AudioFilepath: null,
  ImageFilepath: 'https://electrek.co/wp-content/uploads/sites/3/2022/02/ride1up-roadster-v2-gravel-review_15.jpg',
  Start: 0,
  End: cs_max_int
},{  
  Index: 3,
  StepType: 2,
  Title: "Conclusion",
  AudioFilepath: null,
  ImageFilepath: 'https://electrek.co/wp-content/uploads/sites/3/2022/02/ride1up-roadster-v2-gravel-review_15.jpg'
}];

class App extends React.Component {

    constructor(props){
      super(props);
      var params = new URLSearchParams(window.location.search);

      this.state = {
        trainingName: params.get('id'),
        training: {Steps: defaultSteps},
        currentStep: 0,
        mediaCompleted:false,
        playbackSpeed:1.0,
        savedAudioSpeed:1.0,
        playing:false,
        imageLoaded: false,
        previousDisabled:true
      };

      // this.videoPlayer = React.createRef();
      // this.audioPlayer = React.createRef();
  
      this.onItemClick = this.onItemClick.bind(this);
      this.onDecreaseSpeedClick = this.onDecreaseSpeedClick.bind(this);
      this.onIncreaseSpeedClick = this.onIncreaseSpeedClick.bind(this);
      this.onMediaControlClick = this.onMediaControlClick.bind(this);
      this.onRestartClick = this.onRestartClick.bind(this);
      this.onPreviousClick = this.onPreviousClick.bind(this);
      this.onReplayClick = this.onReplayClick.bind(this);
      this.onNextClick = this.onNextClick.bind(this);
      this.onMediaEnded = this.onMediaEnded.bind(this);
      this.onProgressChanged = this.onProgressChanged.bind(this);
      this.onPlay = this.onPlay.bind(this);
      this.onPause = this.onPause.bind(this);
      this.onReady = this.onReady.bind(this);
    }

    componentDidMount(){
      this.loadTraining(this.state.trainingName)
      .catch((error) => {
        console.error(error);
      });

      // this.fitImageInParent();
      // window.addEventListener('resize', this.fitImageInParent);
      // this.imgElement.addEventListener('load', this.handleImageLoad);
    }

    componentWillUnmount() {
      // window.removeEventListener('resize', this.fitImageInParent);
      // this.imgElement.removeEventListener('load', this.handleImageLoad);
    }

    async loadTraining(trainingName){
      // when picking locally, trainings are in C:\inetpub\wwwroot\Files\Public
      var serverPath = window.location.origin + '/Files/Public/';
      if(serverPath.includes('localhost'))
        serverPath = 'http://localhost/Files/Public/';
      var trainingPath = serverPath + trainingName + '/' + trainingName + '.json';
      try{
        var response = await fetch(trainingPath, {
          mode: 'cors',
        });
        var training = await response.json();
        if(training){
          for(var i = 0; i < training.Steps.length; ++i){
            training.Steps[i].ImageFilepath = this.fixPath(training.Steps[i].ImageFilepath, training.HomeDirectory, serverPath);
            training.Steps[i].AudioFilepath = this.fixPath(training.Steps[i].AudioFilepath, training.HomeDirectory, serverPath);
            training.Steps[i].VideoFilepath = this.fixPath(training.Steps[i].VideoFilepath, training.HomeDirectory, serverPath);
            training.Steps[i].ContentImagePath = this.fixPath(training.Steps[i].ContentImagePath, training.HomeDirectory, serverPath);
          }
          training.CheckList = training.Steps[0].Training.CheckList;
          this.setState({
            training: training,
            imageLoaded: false
          });
        }
      }catch(error){
        console.error(error);
      }
    }

    fixPath(wholePath, homeDirectory, newHomeLocation){
      if(wholePath){
        var relativePath = wholePath.slice(wholePath.indexOf(homeDirectory));
        var newPath = newHomeLocation + relativePath;
        return newPath;
      }
      return wholePath;
    }
  
    onItemClick(item){ 
      this.stopMediaPlayback();
      this.setState({
        currentStep: item,
        imageLoaded: false,
        playbackSpeed: item.VideoFilepath ? 1.0 : this.state.savedAudioSpeed,
        previousDisabled: item.Index <= 0,
        nextDisabled: item.Index >= this.state.training.Steps.length - 1,
        playing: false, 
        mediaCompleted: true
      });
    }

    stopMediaPlayback(){
      if(this.videoPlayer)
        this.videoPlayer.playing = false;
      if(this.audioPlayer)
        this.audioPlayer.playing = false;
    }

    onDecreaseSpeedClick(){
      var curStep = this.state.training.Steps[this.state.currentStep];
      if(curStep.AudioFilepath){
        let newSpeed = Math.max(this.state.playbackSpeed * 0.9, 0.5);
        this.setState({
          playbackSpeed: newSpeed,
          savedAudioSpeed: newSpeed,
          decreaseDisabled: newSpeed <= 0.5,
          increaseDisabled: newSpeed >= 1.4
        });
      }else{
        this.setState({
          playbackSpeed: 1.0,
          decreaseDisabled: false,
          increaseDisabled: false
        });
      }
     }
    onIncreaseSpeedClick(){ 
      var curStep = this.state.training.Steps[this.state.currentStep];
      if(curStep.AudioFilepath){
        let newSpeed = Math.min(this.state.playbackSpeed * 1.1, 1.4);
        this.setState({
          playbackSpeed: newSpeed,
          savedAudioSpeed: newSpeed,
          decreaseDisabled: newSpeed <= 0.5,
          increaseDisabled: newSpeed >= 1.4
        });
      }else{
        this.setState({
          playbackSpeed: 1.0,
          decreaseDisabled: false,
          increaseDisabled: false
        });
      }
    }

    onMediaControlClick(){ 
      var curStep = this.state.training.Steps[this.state.currentStep];
      if(curStep.VideoFilepath)
        this.videoPlayer.playing = !this.state.playing;
      else if(curStep.AudioFilepath)
        this.audioPlayer.playing = !this.state.playing;
    }
    onRestartClick(){
      this.stopMediaPlayback();
      if(this.contentContainer)
        this.contentContainer.maxWidth = '100%';
      this.setState({
        currentStep: 0,
        imageLoaded: false,
        playbackSpeed: this.state.savedAudioSpeed,
        previousDisabled: true,
        nextDisabled: false
      });
    }
    onPreviousClick(){
      this.stopMediaPlayback();
      var newStep = this.state.currentStep - 1;
      if(this.contentContainer)
        this.contentContainer.maxWidth = '100%';
      this.setState({
        currentStep: newStep,
        imageLoaded: false,
        playbackSpeed: this.state.training.Steps[newStep].VideoFilepath ? 1.0 : this.state.savedAudioSpeed,
        previousDisabled: newStep <= 0,
        nextDisabled: newStep >= this.state.training.Steps.length - 1
      });
    }
    onReplayClick(){
      this.stopMediaPlayback();
      if(this.state.currentStep.VideoFilepath){
        this.videoPlayer.seekTo(this.state.step.Start * 1000, 'seconds');
        this.videoPlayer.playing = true;
      }else if(this.state.currentStep.AudioFilepath){
        this.audioPlayer.seekTo(0, 'seconds');
        this.audioPlayer.playing = true;
      }
      this.setState({mediaCompleted: false});
    }
    onNextClick(){
      this.stopMediaPlayback();
      var newStep = this.state.currentStep + 1;
      if(this.contentContainer)
        this.contentContainer.maxWidth = '100%';
      this.setState({
        currentStep: newStep,
        imageLoaded: false,
        playbackSpeed: this.state.training.Steps[newStep].VideoFilepath ? 1.0 : this.state.savedAudioSpeed,
        previousDisabled: newStep <= 0,
        nextDisabled: newStep >= this.state.training.Steps.length - 1
      });
    }
    onMediaEnded(){
      this.setState({mediaCompleted: true});
    }
    onProgressChanged(state){
      if(this.state.currentStep.VideoFilepath){
        if( (this.state.currentStep.End * 1000) < this.videoPlayer.getDuration() &&  state.playedSeconds >= (this.state.currentStep.End * 1000)){
          this.playing = false;
        }
      }
      this.setState(state);
    }
    onPlay(){ this.setState({playing: true}); }
    onPause(){ this.setState({playing: false}); }
    onReady(){
      var curStep = this.state.training.Steps[this.state.currentStep];
      if(curStep.VideoFilepath){
        if(this.videoPlayer.player.isPlaying){ return; }
        if(curStep.Start)
          this.videoPlayer.seekTo(curStep.Start * 1000, 'seconds');
        this.setState({playing: true, mediaCompleted: false});
      }else if(curStep.AudioFilepath){
        if(this.audioPlayer.isPlaying){ return; }
        this.setState({playing: true, mediaCompleted: false});
      }
    }

    fitImageInParent = () => {
      const imageElement = this.imgElement;
      const parentContainer = this.contentContainer;
  
      if (!imageElement || !parentContainer) return;
  
      const parentWidth = parentContainer.parentElement.clientWidth;
      const parentHeight = parentContainer.parentElement.clientHeight;
      const imageAspect = imageElement.naturalWidth / imageElement.naturalHeight;
      const parentAspect = parentWidth / (parentHeight); //1.25; //73 / 40;

      const findNewWidth = parentAspect !== Infinity && parentAspect < 2 && imageElement.naturalWidth > parentWidth && imageElement.naturalHeight > parentHeight;
      const width = findNewWidth ? Math.min(100, (imageAspect/parentAspect)*100) : 100;
  
      parentContainer.style.maxWidth = width + "%";
      parentContainer.style.marginLeft = (width === 100 ? 0 : parentWidth * ((100 - width)/200)) + 'px';
      console.log("done fitting image: imageAspect:{" + imageAspect + "} parentAspect:{" + parentAspect + "} width:{" + width + "}");
    };
  
    handleImageLoad = () => {
      this.setState({ imageLoaded: true }, () => {
        this.fitImageInParent();
      });
    };
  
  render(){
    console.log("rendering PerformTrainingPage, with state: " + JSON.stringify(this.state));

    return(
      <div className='instruct_black_background'>
        <>
        <Body 
          contentComponent={
            <Form 
              id='main-form'
              className='main-form'>
              {this.state.training.Steps[this.state.currentStep].VideoFilepath ? 
                <div id='video-container'
                  ref={contentContainer =>{ this.contentContainer = contentContainer}} 
                  className='content-container d-flex justify-content-center align-items-center'>
                  <ReactPlayer 
                    ref={videoPlayer =>{ this.videoPlayer = videoPlayer}} 
                    url={this.state.training.Steps[this.state.currentStep].VideoFilepath} 
                    controls={false}
                    light={false} 
                    playbackRate={this.state.playbackSpeed} 
                    playing={this.state.playing} 
                    onEnded={this.onMediaEnded}
                    onProgress={this.onProgressChanged}
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                    onReady={this.onReady}
                    />
                  </div>
              :
                <div               
                  id='content-container' 
                  className='content-container justify-content-center align-items-center'>
                  {this.state.training.Steps[this.state.currentStep].StepType === 2 && 
                    !this.state.training.Steps[this.state.currentStep].ImageFilepath && 
                    <PerformTrainingPageInfo 
                      slideTitle={this.state.training.Steps[this.state.currentStep].Title}
                      information={this.state.training.Steps[this.state.currentStep].Information}
                      contentImage={this.state.training.Steps[this.state.currentStep].ContentImagePath}
                    />
                  }
                  {this.state.training.Steps[this.state.currentStep].StepType === 3 && 
                    !this.state.training.Steps[this.state.currentStep].ImageFilepath && 
                    <PerformTrainingCheckListPage 
                      steps={this.state.training.CheckList}
                      currentStep={this.state.training.Steps[this.state.currentStep].Title}
                      trainingTitle={this.state.training.Title}
                    />
                  }
                  {this.state.training.Steps[this.state.currentStep].ImageFilepath && 
                    <div id='image-container'
                      ref={contentContainer =>{ this.contentContainer = contentContainer}} 
                      className='border content-container justify-content-center align-items-center'>
                      <img 
                        id="image-element"
                        ref={imgElement => {this.imgElement = imgElement}} 
                        className="content-image" 
                        src={this.state.training.Steps[this.state.currentStep].ImageFilepath} 
                        alt={this.state.training.Steps[this.state.currentStep].Title}
                        style={{ display: this.state.imageLoaded ? 'block' : 'none' }}
                        onLoad={this.handleImageLoad}
                      />
                    </div>
                  }
                  <ReactAudioPlayer 
                      ref={audioPlayer => {this.audioPlayer = audioPlayer}} 
                      src={this.state.training.Steps[this.state.currentStep].AudioFilepath} 
                      controls={false}
                      autoPlay={true}
                      playbackRate={this.state.playbackSpeed} 
                      playing={this.state.playing} 
                      onEnded={this.onMediaEnded}
                      onProgress={this.onProgressChanged}
                      onPlay={this.onPlay}
                      onPause={this.onPause}
                      onCanPlay={this.onReady}/>
                </div>
              }
          </Form>
          }
          sidebarComponent = {
            <PerformTrainingPageNavigation 
              steps={this.state.training.Steps}
              currentStep={this.state.currentStep}
              handleItemClick={this.onItemClick}
            />
          }
          controlsComponent = {
            <PerformTrainingPageControls 
              playingVideo={this.state.training.Steps[this.state.currentStep].VideoFilepath && !this.state.mediaCompleted}
              description={this.state.training.Steps[this.state.currentStep].Title}
              isPlaying={this.state.playing}
              nextDisabled={this.state.nextDisabled}
              previousDisabled={this.state.previousDisabled}
              decreaseDisabled={this.state.decreaseDisabled}
              increaseDisabled={this.state.increaseDisabled}
              handleDecreaseSpeedClick={this.onDecreaseSpeedClick}
              handleIncreaseSpeedClick={this.onIncreaseSpeedClick}
              handleMediaControlClick={this.onMediaControlClick}
              handleRestartClick={this.onRestartClick}
              handlePreviousClick={this.onPreviousClick}
              handleReplayClick={this.onReplayClick}
              handleNextClick={this.onNextClick}
            />
          }
        />
        </>
      </div>
    );
  }
}

export default App;