import React from 'react';
import './layout.css';

function PageHeader(props){
  return(
    <div>
      <h1 className="aa-page-title">{props.title}</h1>
      {props.subTitle
        ? <p className="page-subtitle">{props.subTitle}</p>
        : null
      }
    </div>
  );
}
  
// Presentational component to display the main page section.
// Bootstrap notes:
// PageSection fills in the rest of the width ("col"), with 2 empty cols right on large+ screen widths ("col-lg-2").
// Add ml-md-2 to page-section div (For med+, set margin-left to 2) to experiment with gap between columns.
//Note: if main div is set to 10 column width ("col-md-10") instead of "col", the ms margin pushes it below the sidebar (breaks the grid). 

function PageSection(props) {
    //the extra container div adds a bit of padding left and right.
    //font-awesome is currently hardwired stand-in for props-based image.
    //if no font-awesome icon provided, don't save room for it.
    
    //by default, give a right margin column. otw follow directive of props.rightMargin

    return(
      <div className="instruct-content-main">
        {props.contentComponent}
      </div>
    );
  }
  
  export {PageSection, PageHeader};
