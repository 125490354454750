import React from 'react';
import {
  Form, 
  ListGroup, 
  ListGroupItem, 
  ButtonGroup, 
  Button, 
  Container,
  Row,
  Col, 
  ListGroupItemHeading
} from 'reactstrap';
import '../layout/layout.css';

function PerformTrainingPageNavigation(props) {
  const { steps, currentStep, handleItemClick } = props;
  return (
    <>
      <ListGroup className='instruct-navigation-listgroup' flush={true}>
        {steps.map((item) => {
          if (item.StepType !== 4) {
            const isActive = item.Index === currentStep || (steps[currentStep].StepType === 4 && item.Index + 1 === currentStep);
            return (
              <ListGroupItem
                className={isActive ? "instruct-selected-navigation-item" : "instruct-navigation-item"}
                key={item.Index}
                onClick={() => handleItemClick(item.Index)}
              >
                <div>
                  <ListGroupItemHeading>{item.Title}</ListGroupItemHeading>
                </div>
              </ListGroupItem>
            );
          } else {
            return null;
          }
        })}
      </ListGroup>
    </>
  );
}

function PerformTrainingPageControls(props){
  const{
    playingVideo,
    description,
    handleDecreaseSpeedClick,
    decreaseDisabled,
    handleIncreaseSpeedClick,
    increaseDisabled,
    isPlaying,
    handleMediaControlClick,
    previousDisabled,
    handleRestartClick,
    handlePreviousClick,
    handleReplayClick,
    nextDisabled,
    handleNextClick
  } = props;
  console.log("PerformTrainingPageControls with: ", props);
  return (
    <>
    <Form className='instruct-control-container'>
      {playingVideo ? 
        <ButtonGroup className='instruct-control-bar' size='large'>
          <Button className='instruct-control-button' color="primary" disabled={decreaseDisabled} onClick={() => handleDecreaseSpeedClick()}>Decrease Speed</Button>
          <legend className='instruct-control-label'>{description}</legend>
          <Button className='instruct-control-button' color="primary" disabled={increaseDisabled}  onClick={() => handleIncreaseSpeedClick()}>Increase Speed</Button>
          <Button className='instruct-control-button' color="primary" onClick={() => handleMediaControlClick()}>{isPlaying ? "Pause" : "Play"}</Button>
        </ButtonGroup>
      :
      <ButtonGroup className='instruct-control-bar' size='large'>
          <Button className='instruct-control-button' color="primary" disabled={previousDisabled}  onClick={() => handleRestartClick()}>Restart</Button>
          <Button className='instruct-control-button' color="primary" disabled={previousDisabled}  onClick={() => handlePreviousClick()}>Previous</Button>
          <Button className='instruct-control-button' color="primary" onClick={() => handleReplayClick()}>Replay</Button>
          <Button className='instruct-control-button' color="primary" disabled={nextDisabled}  onClick={() => handleNextClick()}>Next</Button>
      </ButtonGroup>
      }
    </Form>
    </>
  );
}

function PerformTrainingPageInfo(props){
  const{
    slideTitle,
    information,
    contentImage
  } = props;
  return(
    <>
      <Container className='instruct-information-container border' style={{height:'100%'}} fluid={true}>
        {slideTitle && (
        <Row className="border row-col-borders">
          <legend className='instruct-information-title'>{slideTitle}</legend>
        </Row>
        )}
        {(information || contentImage) && (
          <Row className="row-col-borders" style={slideTitle ? { height: '80%' } : {height:'100%'}}>
            {information && (
              <Col xs={contentImage ? 8 : 12} style={{height:'100%'}} className="row-col-borders">
                <legend className='instruct-information-text'>{information}</legend>
              </Col>
            )}
            {contentImage && (
              <Col xs={information ? 4 : 12} style={{height:'100%'}} className="row-col-borders d-flex justify-content-center align-items-center ">
                <img 
                  className="information-content-image" 
                  src={contentImage} 
                  alt={slideTitle}
                  style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto', objectFit:'contain' }}
                />
              </Col>
            )}
          </Row>
        )}
      </Container>
    </>
  )
}

function PerformTrainingCheckListPage(props){
  const{
    steps,
    currentStep,
    trainingTitle
  } = props;
  var selectedStep = steps.filter((item) => {
    return currentStep === item.Step;
  })[0].Index
  var stepsToShow = {};
  if(steps.length <= 7 ){
    stepsToShow = steps.slice(1, steps.length - 1);
  }else if(steps[selectedStep].Index < 4){
    stepsToShow = steps.slice(1, 6);
  }else if(steps[selectedStep].Index >= steps.length - 4){
    stepsToShow = steps.slice(-6).slice(0,5);
  }else{
    stepsToShow = steps.slice(selectedStep - 2, selectedStep + 3)
  }
  return (
    <Container className="instruct-checklist-container" fluid={true}>
      <legend className="instruct-checklist-title">{trainingTitle}</legend>
      <ListGroup className="instruct-checklist-listgroup list-group-flush" flush={true} variant="flush">
        {stepsToShow.map((item) => {
          return (
            <ListGroupItem
              className={item.Index === selectedStep ? "instruct-selected-checklist-item" : "instruct-checklist-item"}
              key={item.Index}
              active={item.Index === selectedStep}
            >
              <div>
                <ListGroupItemHeading>{item.Step}</ListGroupItemHeading>
                {item.Index === selectedStep && item.SubSteps &&
                  item.SubSteps.split('\n').map((subStep, index) => (
                    <div className="substep-string" key={index}>
                      {subStep}
                    </div>
                  ))}
              </div>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </Container>
  );
}

export { PerformTrainingPageNavigation, PerformTrainingPageControls, PerformTrainingPageInfo, PerformTrainingCheckListPage};
