import React from 'react';
import './PageControls.css';
import {
  Container,
  Row,
  Col 
} from 'reactstrap';


//Presentational component to display the Back and Continue buttons.
//The Continue button has 2 ways to go to the next step:
//  1. With a callback to determine the next step (onContinue), which is the typical way, or 
//  2. If no callback available, it uses a string for the nextRoute ("/gather-info/main"). 
function PageControls(props) {
  return(
    <Container className="instruct-page-controls" role="navigation">
        {props.contentComponent}
    </Container>
  );
}

export default PageControls;

